// Breadcrumbs.tsx
import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useCustomTheme } from '../../contexts/ThemeContext';
import { UploadMember, UploadPublic } from '../../workers/ApiWorker';
import Chip from '@mui/material/Chip';
import {
    GamesLink,
    HomeLink,
    LinksProps,
    ViewUploadsLink,
} from '../../helpers/Links';
import { useNavigate } from 'react-router-dom';

interface BreadcrumbsProps {
    upload: UploadPublic | UploadMember;
    isMobile: boolean;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ upload, isMobile }) => {
    const navigate = useNavigate();

    const { theme } = useCustomTheme();

    const capitalizeWords = (str: string) => {
        return str
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    function BreadcrumbItem({
        link,
        type,
    }: {
        link: LinksProps;
        type?: string;
    }) {
        // Clone the icon with custom size
        const IconWithSize = link.showIconBreadcrumb
            ? React.cloneElement(link.icon, {
                  style: { fontSize: '1.15rem' }, // Increase icon size
              })
            : undefined; // No icon if showIconBreadcrumb is false

        // Conditionally use `type` if provided, otherwise use `link.name`
        const label = capitalizeWords(type ? type : link.name);

        return (
            <Chip
                sx={{
                    color:
                        type === 'filled'
                            ? theme.palette.text.primary
                            : theme.palette.text.secondary,
                    paddingLeft: 1.2, // Increase left padding
                    paddingRight: type ? 1.2 : 0.9,
                }}
                variant={type ? 'filled' : 'outlined'}
                color="primary"
                icon={IconWithSize}
                label={label}
                clickable={!type}
                onClick={() => {
                    if (!type) {
                        navigate(link.path);
                    }
                }}
            />
        );
    }

    function BreadcrumbSeperator() {
        return (
            <Typography
                variant={'h5'}
                sx={{
                    color: theme.palette.text.secondary,
                    marginLeft: 1,
                    marginRight: 1,
                }}
            >
                /
            </Typography>
        );
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2.5 }}>
            <BreadcrumbItem link={HomeLink(isMobile)} />
            <BreadcrumbSeperator />
            <BreadcrumbItem link={GamesLink(isMobile)} />
            <BreadcrumbSeperator />
            <BreadcrumbItem
                link={ViewUploadsLink(isMobile)}
                type={upload.type.name}
            />
        </Box>
    );
};

export default Breadcrumbs;
