// UserLogin.tsx
import React from 'react';
import Login from '@mui/icons-material/Login';
import TopAppBarItemButtonLink from '../navigation/navbar/TopAppBarItemButtonLink';

const UserLogin: React.FC<{}> = () => {
    return (
        <>
            <TopAppBarItemButtonLink
                icon={<Login />}
                text={'Login'}
                link={'/login'}
            />
        </>
    );
};

export default UserLogin;
