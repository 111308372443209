// Swipe.tsx
import React from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useCustomTheme } from '../../../contexts/ThemeContext';
import { discoveryToolBar } from '../../../helpers/Themes';

const drawerBleeding = 56;

interface Props {
    window?: () => Window;
    open: boolean;
    handleSwipeOpen: (value: boolean) => void;
    title: string;
    body: () => JSX.Element;
}

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: discoveryToolBar,
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.text.disabled,
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

const Swipe: React.FC<Props> = ({
    window,
    open,
    handleSwipeOpen,
    title,
    body,
}) => {
    const { theme } = useCustomTheme();

    const toggleDrawer = (newOpen: boolean) => () => {
        handleSwipeOpen(newOpen);
    };

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            {open ? (
                <SwipeableDrawer
                    container={container}
                    anchor="bottom"
                    open={open}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    swipeAreaWidth={drawerBleeding}
                    disableSwipeToOpen={false}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        '& .MuiPaper-root': {
                            height: `calc(50% + ${drawerBleeding}px)`,
                            top: 'auto',
                            bottom: 0,
                            overflow: 'visible',
                        },
                    }}
                >
                    <StyledBox
                        sx={{
                            position: 'absolute',
                            top: -drawerBleeding,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            visibility: 'visible',
                            right: 0,
                            left: 0,
                        }}
                    >
                        <Puller />
                        <Typography
                            variant="h4"
                            sx={{ p: 2, color: theme.palette.text.secondary }}
                        >
                            {title}
                        </Typography>
                    </StyledBox>
                    <StyledBox
                        sx={{
                            px: 2,
                            pb: 2,
                            height: '100%',
                            overflow: 'auto',
                        }}
                    >
                        {body()}
                    </StyledBox>
                </SwipeableDrawer>
            ) : null}
        </>
    );
};

export default Swipe;
