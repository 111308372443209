import React, { useEffect } from 'react';
import {
    Box,
    Button,
    Typography,
    Link,
    FormControlLabel,
    Checkbox,
    Fade,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import logo from '../images/discovery-logo-2.png';
import { useApi } from '../contexts/ApiContext';
import { useNavigate } from 'react-router-dom';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import axios from 'axios';
import { unknownError } from '../helpers/ApiResponses';
import ErrorAlert from '../components/universal/alerts/ErrorAlert';
import TextInput from '../components/universal/inputs/TextInput';
import { fadeTimeout } from '../helpers/Themes';

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

// Define validation schema using Yup
const validationSchema = Yup.object({
    email: Yup.string()
        .email('Enter a valid email')
        .required('Email is required'),
    password: Yup.string()
        .min(10, 'Password should be of minimum 10 characters length')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
            'Password must contain at least 1 uppercase character, 1 lowercase character, and 1 number'
        )
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match') // Validation rule to match password
        .required('Confirm Password is required'),
    username: Yup.string()
        .min(3, 'Display name should be of minimum 3 characters length')
        .max(25, 'Display name should be of maximum 25 characters length')
        .required('Display name is required'),
    confirmation: Yup.boolean().oneOf(
        [true],
        'You must accept the terms and conditions'
    ),
});

const RegisterPage: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate(); // Hook for navigation
    const { storeTokenAndUser } = useApi();

    const apiWorker = useWorker(createApiWorker);
    const [error, setError] = React.useState<string>('');
    const [showError, setShowError] = React.useState<boolean>(false);

    useEffect(() => {}, []);

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Box
                sx={{
                    height: '90vh', // Full height of the viewport
                    display: 'flex',
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center', // Center vertically
                    padding: 2, // Padding for mobile responsiveness
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '2rem',
                        width: '100%',
                        maxWidth: '400px', // Limits the form's max width
                    }}
                >
                    {/* Logo */}
                    <Box
                        component="img"
                        src={logo} // Replace with your actual logo
                        alt="Logo"
                        sx={{ width: '80px', marginBottom: '2rem' }}
                    />

                    {/* Login Title */}
                    <Typography
                        variant="h2"
                        sx={{ fontWeight: 700, marginBottom: '0rem' }}
                    >
                        CREATE ACCOUNT
                    </Typography>

                    {/* Subtitle */}
                    <Typography
                        variant="body2"
                        sx={{
                            marginBottom: '2rem',
                            color: theme.palette.text.disabled,
                        }}
                    >
                        join the discovery platform
                    </Typography>

                    {showError && <ErrorAlert message={error}></ErrorAlert>}

                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                            confirmPassword: '',
                            username: '',
                            confirmation: false,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { resetForm }) => {
                            setShowError(false);
                            try {
                                await apiWorker.createUser(
                                    values.email,
                                    values.password,
                                    values.username
                                );
                                const response = await apiWorker.login(
                                    values.email,
                                    values.password
                                );
                                storeTokenAndUser(response.accessToken);
                                navigate('/');
                            } catch (error) {
                                if (axios.isAxiosError(error)) {
                                    if (error.response?.data) {
                                        setError(error.response?.data.message);
                                    } else {
                                        setError(error.message);
                                    }
                                } else {
                                    setError(unknownError().message);
                                }
                                setShowError(true);
                            }
                        }}
                    >
                        {({
                            errors,
                            touched,
                            values,
                            handleChange,
                            isSubmitting,
                            handleBlur,
                            isValid,
                            setFieldValue,
                        }) => (
                            <Form>
                                <TextInput
                                    id={'username'}
                                    label={'Display name *'}
                                    value={values.username}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    hasSubmitted={isSubmitting}
                                    touched={touched.username}
                                    errors={errors.username}
                                    enableLabel={true}
                                />

                                <TextInput
                                    id={'email'}
                                    label={'Email *'}
                                    value={values.email}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    hasSubmitted={isSubmitting}
                                    touched={touched.email}
                                    errors={errors.email}
                                    enableLabel={true}
                                />

                                <TextInput
                                    id={'password'}
                                    label={'Password *'}
                                    value={values.password}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    hasSubmitted={isSubmitting}
                                    touched={touched.password}
                                    errors={errors.password}
                                    type="password"
                                    enableLabel={true}
                                />

                                <TextInput
                                    id={'confirmPassword'}
                                    label={'Confirm Password *'}
                                    value={values.confirmPassword}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    hasSubmitted={isSubmitting}
                                    touched={touched.confirmPassword}
                                    errors={errors.confirmPassword}
                                    type="password"
                                    enableLabel={true}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="confirmation"
                                            name="confirmation"
                                            checked={values.confirmation}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={isSubmitting}
                                            sx={{
                                                color: theme.palette.primary
                                                    .main,
                                            }}
                                        />
                                    }
                                    label={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: theme.palette.text
                                                        .secondary,
                                                    marginTop: 0.1,
                                                }}
                                            >
                                                I agree to the&nbsp;
                                                <Typography
                                                    component="span"
                                                    variant="caption"
                                                    sx={{
                                                        color: theme.palette
                                                            .warning.main,
                                                    }}
                                                >
                                                    Terms of Service&nbsp;
                                                </Typography>
                                                and&nbsp;
                                                <Typography
                                                    component="span"
                                                    variant="caption"
                                                    sx={{
                                                        color: theme.palette
                                                            .warning.main,
                                                    }}
                                                >
                                                    Privacy Policy
                                                </Typography>
                                            </Typography>
                                        </Box>
                                    }
                                />

                                <Button
                                    fullWidth
                                    type="submit"
                                    disabled={isSubmitting || !isValid}
                                    variant="contained"
                                    sx={{
                                        padding: '0.8rem',
                                        marginTop: 3,
                                        marginBottom: 1.8,
                                    }}
                                >
                                    CREATE
                                </Button>
                            </Form>
                        )}
                    </Formik>

                    {/* Register Link */}
                    <Typography variant="body2" sx={{ color: '#AAA' }}>
                        <Link
                            href="/login"
                            underline="hover"
                            variant="body2"
                            sx={{ color: theme.palette.text.disabled }}
                        >
                            <i>already a member? </i>{' '}
                            <i>
                                <b>Login!</b>
                            </i>
                        </Link>
                    </Typography>
                </Box>
            </Box>
        </Fade>
    );
};

export default RegisterPage;
