// GamesPage.tsx
import React from 'react';
import { fadeTimeout } from '../helpers/Themes';
import { Fade } from '@mui/material';

const GamesPage: React.FC<{}> = () => {
    return (
        <Fade in={true} timeout={fadeTimeout}>
            <p>GamePage</p>
        </Fade>
    );
};

export default GamesPage;
