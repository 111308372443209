// HomePage.tsx
import React from 'react';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Socials from '../../helpers/Socials';
import { useCustomTheme } from '../../contexts/ThemeContext';
import { UploadMember, UploadPublic } from '../../workers/ApiWorker';

interface CoreInformationProps {
    upload: UploadPublic | UploadMember;
}

const CoreInformation: React.FC<CoreInformationProps> = ({ upload }) => {
    const { theme } = useCustomTheme();

    return (
        <>
            <Typography variant={'h1'}>{upload.name.toUpperCase()}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                    variant="body2"
                    sx={{ color: theme.palette.text.disabled }}
                >
                    Submitted By&nbsp;&nbsp;
                    <Typography
                        component="span"
                        variant="caption"
                        sx={{
                            color: theme.palette.warning.main,
                            marginTop: 0.2,
                        }}
                    >
                        {upload.author.username.toUpperCase()}
                    </Typography>
                </Typography>
            </Box>
            {(upload.author.twitter || upload.author.patreon) && (
                <Grid sx={{ marginTop: 1.5 }}>
                    <Socials
                        twitter={upload.author.twitter}
                        patreon={upload.author.patreon}
                    ></Socials>
                </Grid>
            )}
        </>
    );
};

export default CoreInformation;
