// Carousel.tsx
import React, { useEffect, useState } from 'react';
import { Fade, Grid } from '@mui/material';
import PreviewCard from '../universal/cards/PreviewCard';
import {
    UploadMember,
    UploadPublic,
    UploadTypeNames,
} from '../../workers/ApiWorker';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules'; // Removed Pagination import
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useCustomTheme } from '../../contexts/ThemeContext';
import Box from '@mui/material/Box';
import LoadingPreviewCard from '../universal/cards/LoadingPreviewCard';
import { fadeTimeout } from '../../helpers/Themes';

interface CarouselProps {
    data: UploadPublic[] | UploadMember[];
    slidesPerView: number;
    type: UploadTypeNames.CHARACTER | UploadTypeNames.ARENA;
    index: number; // Added index prop to differentiate instances
    isMobile: boolean;
}

const Carousel: React.FC<CarouselProps> = ({
    data,
    slidesPerView,
    type,
    index,
    isMobile,
}) => {
    const { theme } = useCustomTheme();
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    // // Calculate padding based on width
    // const calculatePadding = () => {
    //     if (width <= 440) {
    //         return 0;
    //     } else if (width <= 500) {
    //         return 2;
    //     } else if (width <= 565) {
    //         return 4;
    //     } else if (width <= 629) {
    //         return 6; // Small screens
    //     } else if (width <= 846) {
    //         return 0; // Medium screens
    //     } else if (width <= 1019) {
    //         return 4; // Medium screens
    //     } else if (width <= 1728) {
    //         // This is my resolution
    //         return 0;
    //     } else if (width <= 1809) {
    //         // This is my resolution
    //         return 2;
    //     } else {
    //         return 0; // Extra large screens
    //     }
    // };

    // Calculate padding based on width
    const calculatePadding = () => {
        return 0; // Extra large screens
    };

    const padding = calculatePadding();

    return (
        <Grid item xs={12} sx={{ marginBottom: 5 }}>
            {data.length > 0 ? (
                <Fade in={true} timeout={fadeTimeout}>
                    <div>
                        <>
                            <style>
                                {`
                .swiper-container {
                    position: relative; /* Position relative to allow absolute positioning of arrows */
                }

                .custom-prev-${index},
                .custom-next-${index} {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%); /* Center vertically */
                    z-index: 10; /* Make sure arrows are on top */
                    cursor: pointer;
                    font-size: 25px; /* Increased size for bigger arrow icons */
                    background-color: transparent; /* Remove background */
                    border: none; /* Remove border */
                    outline: none; /* Remove outline */
                    padding: 10px; /* Optional padding */
                    color: ${theme.palette.text.secondary}
                }

                .custom-prev-${index} {
                    left: -50px; /* Position 50px outside on the left */
                }

                .custom-next-${index} {
                    right: -60px; /* Position 50px outside on the right */
                }

                /* Hover effects for custom arrows */
                .custom-prev-${index}:hover,
                .custom-next-${index}:hover {
                    opacity: 0.8; /* Change opacity on hover */
                }

                /* Hide pagination dots */
                .swiper-pagination {
                    display: none; /* Hide the pagination dots */
                }
                `}
                            </style>
                            <div className="swiper-container">
                                <Swiper
                                    spaceBetween={30}
                                    speed={1000}
                                    navigation={{
                                        nextEl: `.custom-next-${index}`, // Unique class for next button
                                        prevEl: `.custom-prev-${index}`, // Unique class for prev button
                                    }}
                                    modules={[Navigation]} // Removed Pagination from modules
                                    breakpoints={{
                                        750: {
                                            slidesPerView: slidesPerView / 2,
                                            slidesPerGroup: slidesPerView / 2,
                                        },
                                        900: {
                                            slidesPerView:
                                                type === UploadTypeNames.ARENA
                                                    ? slidesPerView
                                                    : slidesPerView / 2,
                                            slidesPerGroup:
                                                type === UploadTypeNames.ARENA
                                                    ? slidesPerView
                                                    : slidesPerView / 2,
                                        },
                                        1020: {
                                            slidesPerView:
                                                type === UploadTypeNames.ARENA
                                                    ? slidesPerView
                                                    : slidesPerView - 1,
                                            slidesPerGroup:
                                                type === UploadTypeNames.ARENA
                                                    ? slidesPerView
                                                    : slidesPerView - 1,
                                        },
                                        1220: {
                                            slidesPerView:
                                                type === UploadTypeNames.ARENA
                                                    ? slidesPerView
                                                    : slidesPerView - 1,
                                            slidesPerGroup:
                                                type === UploadTypeNames.ARENA
                                                    ? slidesPerView
                                                    : slidesPerView - 1,
                                        },
                                        1340: {
                                            slidesPerView:
                                                type === UploadTypeNames.ARENA
                                                    ? slidesPerView + 1
                                                    : slidesPerView,
                                            slidesPerGroup:
                                                type === UploadTypeNames.ARENA
                                                    ? slidesPerView + 1
                                                    : slidesPerView,
                                        },
                                        1810: {
                                            slidesPerView:
                                                type === UploadTypeNames.ARENA
                                                    ? slidesPerView + 1
                                                    : slidesPerView + 2,
                                            slidesPerGroup:
                                                type === UploadTypeNames.ARENA
                                                    ? slidesPerView + 1
                                                    : slidesPerView + 2,
                                        },
                                        2335: {
                                            slidesPerView:
                                                type === UploadTypeNames.ARENA
                                                    ? slidesPerView * 2
                                                    : slidesPerView + 2,
                                            slidesPerGroup:
                                                type === UploadTypeNames.ARENA
                                                    ? slidesPerView * 2
                                                    : slidesPerView + 2,
                                        },
                                    }}
                                    onReachEnd={() => {}}
                                >
                                    {data.map((upload) => (
                                        <SwiperSlide key={upload.uuid}>
                                            <Grid
                                                item
                                                sx={{
                                                    paddingLeft: padding,
                                                    paddingRight: padding,
                                                }}
                                            >
                                                <PreviewCard
                                                    uuid={upload.uuid}
                                                    username={
                                                        upload.author.username
                                                    }
                                                    title={upload.name}
                                                    description={
                                                        upload.miniDescription
                                                    }
                                                    image={upload.images.main}
                                                    games={upload.games}
                                                    type={upload.type}
                                                />
                                            </Grid>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                {/* Custom Navigation Buttons */}
                                <div className={`custom-prev-${index}`}>
                                    <ArrowBackIosIcon fontSize="inherit" />
                                </div>{' '}
                                {/* Unique class name for prev */}
                                <div className={`custom-next-${index}`}>
                                    <ArrowForwardIosIcon fontSize="inherit" />
                                </div>{' '}
                                {/* Unique class name for next */}
                            </div>
                        </>
                    </div>
                </Fade>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%', // Set appropriate height
                        width: '100%',
                    }}
                >
                    <Grid item xs={12} sm={6} md={4}>
                        <div>
                            {/* Show LoadingPreviewCard for the remaining spots */}
                            <LoadingPreviewCard invisible={true} />
                        </div>
                    </Grid>
                    {/*<Typography variant="caption" color={theme.palette.text.disabled}>*/}
                    {/*    No Uploads Found*/}
                    {/*</Typography>*/}
                </Box>
            )}
        </Grid>
    );
};

export default Carousel;
