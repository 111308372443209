import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import {
    UploadMember,
    UploadPublic,
    VersionMember,
    VersionPublic,
} from '../../workers/ApiWorker';
import { convertSimple } from '../../helpers/Date';
import Box from '@mui/material/Box';
import UserAvatar from '../user/UserAvatar';
import { useApi } from '../../contexts/ApiContext';

interface DetailedInformationProps {
    upload: UploadPublic | UploadMember;
    version: VersionPublic | VersionMember;
}

const DetailedInformation: React.FC<DetailedInformationProps> = ({
    upload,
    version,
}) => {
    const { user, token } = useApi();
    const { theme } = useCustomTheme();

    return (
        <>
            <Grid
                container
                spacing={2}
                textAlign="center"
                sx={{ marginTop: 0.5 }}
            >
                <Grid item xs={4} sm={4} md={4} lg={1}>
                    <Typography
                        variant="body2"
                        sx={{ color: theme.palette.text.disabled }}
                    >
                        date
                    </Typography>
                    <Typography variant="h5">
                        {convertSimple(version.createdAt)}
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={1}>
                    <Typography variant="h5">
                        {upload.stats.views + 1}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: theme.palette.text.disabled }}
                    >
                        views
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={1}>
                    <Typography
                        variant="body2"
                        sx={{ color: theme.palette.text.disabled }}
                    >
                        games
                    </Typography>
                    {upload.games.map((game, index) => (
                        <div key={index}>
                            {game.images && game.images.logo ? (
                                <img
                                    src={game.images.logo}
                                    alt={game.name}
                                    style={{ width: '50px', height: 'auto' }}
                                />
                            ) : (
                                <Typography variant="h5">
                                    {game.name.toUpperCase()}
                                </Typography>
                            )}
                        </div>
                    ))}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                    {upload.collaborators && upload.collaborators.length > 0 ? (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                justifyContent: {
                                    xs: 'center',
                                    lg: 'flex-start',
                                }, // Center on small, left on large
                            }}
                        >
                            {upload.collaborators.map((collaborator, index) => (
                                <UserAvatar
                                    key={index}
                                    backup={collaborator.username}
                                    dimensions={36}
                                    border={true}
                                    userProfile={collaborator}
                                />
                            ))}
                        </Box>
                    ) : (
                        <></>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    {token && user ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: {
                                    xs: 'center',
                                    lg: 'flex-end',
                                }, // Center on small, right on large
                                gap: 1,
                            }}
                        >
                            <Button variant="contained" type="submit">
                                Subscribe
                            </Button>
                            <Button variant="contained" type="submit">
                                Report
                            </Button>
                        </Box>
                    ) : null}
                </Grid>
            </Grid>
        </>
    );
};

export default DetailedInformation;
