import React, { useEffect, useState } from 'react';
import { Button, Fade, Typography } from '@mui/material';
import { SuspendUserProfileProps, User } from '../workers/ApiWorker';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { useApi } from '../contexts/ApiContext';
import { fadeTimeout, getOverallPadding } from '../helpers/Themes';
import Box from '@mui/material/Box';
import PageTopSection from '../components/universal/pageTopSection/PageTopSection';
import { useCustomTheme } from '../contexts/ThemeContext';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import DataTable from '../components/universal/tables/DataTable';
import { Lens } from '@mui/icons-material';
import { convertSimpleWithTime } from '../helpers/Date';
import Chip from '@mui/material/Chip';
import { upperCaseFirst } from 'upper-case-first';
import CustomModal from '../components/universal/modals/CustomModal';
import axios from 'axios';
import { unknownError } from '../helpers/ApiResponses';
import { Form, Formik } from 'formik';
import TextInput from '../components/universal/inputs/TextInput';
import * as Yup from 'yup';
import ErrorAlert from '../components/universal/alerts/ErrorAlert';
import Filters from '../components/filters/Filters';
import { adminFilters } from '../helpers/AdminFilters';

interface AdminPageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
}

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

const AdminPage: React.FC<AdminPageProps> = ({ isMobile, padding, user }) => {
    const { theme } = useCustomTheme();
    const { token } = useApi();
    const apiWorker = useWorker(createApiWorker);

    const [error, setError] = React.useState<string | undefined>(undefined);
    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]); // State for selected user
    const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false); // State for modal visibility

    const [selectedFilter, setSelectedFilter] = useState(
        adminFilters()[0].value
    );

    const handleSelectedFilter = (value: string) => {
        setSelectedFilter(value);
    };

    const showSuspendUsersModal = () => {
        setError(undefined);
        setIsSuspendModalOpen(true);
    };
    const closeSuspendUserModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setError(undefined);
        setIsSuspendModalOpen(false);
    };

    const handleSuspendUsers = async (
        values: { reason: string },
        resetForm: () => void
    ) => {
        if (!token || !user) {
            return;
        }

        const data: SuspendUserProfileProps = {
            ...values,
            uuids: selectedUsers.map(
                (selectedUser) => selectedUser.userProfile.uuid
            ),
        };

        try {
            const results = await apiWorker.suspendUserProfiles(data, token);

            // Create a new copy of selectedUsers and filter it based on UUIDs in alreadySuspended or failed
            const successfulSuspendedUsers = [...selectedUsers].filter(
                (selectedUser) => {
                    const selectedUserUuid = selectedUser.userProfile.uuid;
                    // Check if the UUID is in alreadySuspended or failed lists, and remove it if found
                    const isAlreadySuspended =
                        results.data.alreadySuspended.includes(
                            selectedUserUuid
                        );
                    const isFailed =
                        results.data.failed.includes(selectedUserUuid);
                    return !isAlreadySuspended && !isFailed;
                }
            );

            // Create a new copy of the 'users' array
            let newUsers = [...users]; // Assuming 'users' is the array of all users

            newUsers.forEach((newUser) => {
                const isSuspended = successfulSuspendedUsers.some(
                    (suspendedUser) =>
                        suspendedUser.userProfile.uuid ===
                        newUser.userProfile.uuid
                );

                if (isSuspended) {
                    // Update the accountStatus to 'suspended'
                    newUser.userProfile.accountStatus = 'suspended';
                }
            });

            setUsers(newUsers);
            closeSuspendUserModal(resetForm);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.data) {
                    setError(error.response?.data.message);
                } else {
                    setError(error.message);
                }
            } else {
                setError(unknownError().message);
            }
        }
    };

    // Define validation schema using Yup
    const validationSchemaSuspendUsers = Yup.object({
        reason: Yup.string().required('Reason is required'),
    });

    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        if (selectedRows.length > 0) {
            const selectedUsersArray = users.filter((user) =>
                selectedRows.includes(user.userProfile.uuid)
            );
            setSelectedUsers(selectedUsersArray);
        } else {
            setSelectedUsers([]);
        }
    }, [selectedRows]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (token) {
            const getUsers = async () => {
                try {
                    const response = await apiWorker.getUsers(token, {
                        count: 1000,
                    });
                    setUsers(response.data);
                } catch (error) {
                    console.error(error);
                }
            };

            getUsers().catch(console.error);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const columns: GridColDef[] = [
        {
            field: 'username',
            headerName: 'Nickname',
            valueGetter: (value, row) => row.userProfile.username,
        },
        {
            field: 'uuid',
            headerName: 'UUID',
        },
        {
            field: 'email',
            headerName: 'Email',
        },
        {
            field: 'accountType',
            headerName: 'Account Type',
            valueGetter: (value, row) => {
                const accountType = row.userProfile.accountType;
                return upperCaseFirst(accountType);
            },
        },
        {
            field: 'canBypass',
            headerName: 'Upload Approval Required?',
            type: 'boolean',
            valueGetter: (value, row) => !row.userProfile.canBypass,
            renderCell: (params) => {
                const canBypass = params.row.userProfile.canBypass;
                return (
                    <Lens
                        style={{
                            color: !canBypass
                                ? theme.palette.success.main
                                : theme.palette.error.main, // Green if canBypass is true, red if false
                            fontSize: '20px', // You can adjust the size of the icon
                        }}
                    />
                );
            },
        },
        {
            field: 'verifiedAt',
            headerName: 'Verified',
        },
        {
            field: 'ipAddress',
            headerName: 'IP Address',
        },
        {
            field: 'lastLoggedInAt',
            headerName: 'Last Active',
            type: 'dateTime',
            renderCell: (params) => {
                if (params.row.lastLoggedInAt !== null) {
                    return convertSimpleWithTime(params.row.lastLoggedInAt);
                }

                return convertSimpleWithTime(params.row.createdAt);
            },
            valueGetter: (value, row) => {
                if (row.lastLoggedInAt !== null) {
                    return new Date(row.lastLoggedInAt);
                }

                return new Date(row.createdAt);
            },
        },
        {
            field: 'accountStatus',
            headerName: 'Account Status',
            renderCell: (params) => {
                const accountStatus = params.row.userProfile.accountStatus;

                return (
                    <Chip
                        label={upperCaseFirst(accountStatus)}
                        sx={{
                            backgroundColor:
                                accountStatus === 'active'
                                    ? theme.palette.success.main
                                    : theme.palette.error.main,
                            color: theme.palette.text.primary,
                        }}
                    />
                );
            },
            valueGetter: (value, row) => row.userProfile.accountStatus,
        },
    ];

    return (
        <>
            <Fade in={true} timeout={fadeTimeout}>
                <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                    <PageTopSection
                        isMobile={isMobile}
                        padding={padding}
                        advancedContent={
                            <>
                                {/* Filters positioned at the bottom */}
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        bottom: isMobile ? 20 : 0, // Align the filters to the bottom of the container
                                        left: 0,
                                        right: 0, // Ensures it stretches to both ends
                                    }}
                                >
                                    <Filters
                                        isMobile={isMobile}
                                        selectedFilter={selectedFilter}
                                        filters={adminFilters}
                                        handleSelectedFilter={
                                            handleSelectedFilter
                                        }
                                        padding={padding}
                                        user={user}
                                    />
                                </Box>
                            </>
                        }
                    />

                    <Box
                        sx={{
                            paddingLeft: getOverallPadding(isMobile, padding),
                            paddingRight: getOverallPadding(isMobile, padding),
                        }}
                    >
                        {(() => {
                            switch (selectedFilter) {
                                case 'user-management':
                                    return (
                                        <Fade in={true} timeout={fadeTimeout}>
                                            <div>
                                                <DataTable
                                                    isMobile={isMobile}
                                                    columns={columns}
                                                    rows={users}
                                                    padding={padding}
                                                    setSelectedRows={
                                                        setSelectedRows
                                                    }
                                                    storedSettingsName={
                                                        'AdminUsers'
                                                    }
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'flex-end',
                                                            marginTop: 2, // optional for spacing above the button
                                                        }}
                                                    >
                                                        <Button
                                                            disabled={
                                                                !selectedUsers.length
                                                            }
                                                            type="button"
                                                            variant="contained"
                                                            color="primary"
                                                            sx={{
                                                                padding:
                                                                    '0.8rem',
                                                            }}
                                                            onClick={() =>
                                                                showSuspendUsersModal()
                                                            }
                                                        >
                                                            SUSPEND USERS
                                                        </Button>
                                                    </Box>
                                                </DataTable>
                                            </div>
                                        </Fade>
                                    );
                                default:
                                    return (
                                        <Typography
                                            variant="h6"
                                            color="text.secondary"
                                        >
                                            No data available for this filter.
                                        </Typography>
                                    );
                            }
                        })()}
                    </Box>
                </Box>
            </Fade>
            {/* Modal Component */}
            <Formik
                initialValues={{
                    reason: '',
                }}
                validationSchema={validationSchemaSuspendUsers}
                onSubmit={async (values, { resetForm }) => {
                    await handleSuspendUsers(values, resetForm);
                }}
            >
                {({
                    errors,
                    touched,
                    values,
                    handleChange,
                    isSubmitting,
                    handleBlur,
                    isValid,
                    handleSubmit,
                    resetForm,
                }) => (
                    <CustomModal
                        isOpen={isSuspendModalOpen}
                        onClose={() => closeSuspendUserModal(resetForm)}
                        confirmLabel="Suspend"
                        onConfirm={handleSubmit}
                        isSubmitting={isSubmitting}
                        isValid={isValid}
                    >
                        {/* Modal content passed directly as children */}
                        <Typography
                            variant="h3"
                            color={theme.palette.text.secondary}
                        >
                            SUSPEND USERS
                        </Typography>
                        <Typography
                            variant="body2"
                            color={theme.palette.warning.main}
                            sx={{ marginBottom: 3 }}
                        >
                            Users who are already suspended will be ignored.
                        </Typography>
                        {error && <ErrorAlert message={error}></ErrorAlert>}
                        {selectedUsers.length > 0 && (
                            <>
                                <Typography sx={{ mt: 2 }} variant="body1">
                                    Are you sure you want to suspend these
                                    users?
                                    <Box
                                        sx={{
                                            maxHeight: 200,
                                            overflowY: 'auto', // Enable vertical scrolling
                                        }}
                                    >
                                        <ul>
                                            {selectedUsers.map((x) => (
                                                <li key={x.uuid}>
                                                    {x.userProfile.username}{' '}
                                                    {x.userProfile
                                                        .accountStatus ===
                                                        'suspended' && (
                                                        <span
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            (Already Suspended)
                                                        </span>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </Box>
                                </Typography>
                                <Form>
                                    <TextInput
                                        id={'reason'}
                                        label={'Reason'}
                                        value={values.reason}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        hasSubmitted={isSubmitting}
                                        touched={touched.reason}
                                        errors={errors.reason}
                                        enableLabel={true}
                                    />
                                </Form>
                            </>
                        )}
                    </CustomModal>
                )}
            </Formik>
        </>
    );
};

export default AdminPage;
