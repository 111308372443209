import React, { ChangeEvent } from 'react';
import { Checkbox, FormControl, FormControlLabel, Grid } from '@mui/material';
import LabelInput from './LabelInput';
import CaptionInput from './CaptionInput';

interface TextInputProps {
    id: string;
    label: string;
    valueOn: string | boolean;
    valueOff: undefined | boolean;
    handleChange: (field: string, value: any) => void; // Updated
    hasSubmitted: boolean;
    value?: string | boolean;
    enableLabel?: boolean;
    isMobile?: boolean;
    caption?: string;
}

const CheckboxInput: React.FC<TextInputProps> = ({
    id,
    label,
    valueOn,
    valueOff,
    handleChange,
    hasSubmitted,
    value,
    enableLabel,
    isMobile,
    caption,
}) => {
    // Function to handle checkbox change
    const handleCheckboxChange = (e: ChangeEvent<any>) => {
        const isChecked = e.target.checked;
        handleChange(id, isChecked ? valueOn : valueOff);
    };
    // Function to handle "Enter" key press
    return (
        <FormControl fullWidth margin="normal">
            <Grid
                container
                alignItems={isMobile ? 'flex-start' : 'flex-start'}
                spacing={2}
            >
                {/* Label Area */}
                {!enableLabel && (
                    <LabelInput
                        label={label}
                        enableLabel={enableLabel}
                        isMobile={isMobile}
                        caption={caption}
                    />
                )}

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={isMobile ? 12 : enableLabel ? 12 : 9.5}
                    lg={enableLabel ? 12 : 9.8}
                    xl={enableLabel ? 12 : 10.25}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: -13,
                    }} // Align items to the top-left
                >
                    <FormControlLabel
                        control={<Checkbox id={`${id}-checkbox`} />}
                        label={enableLabel ? label : ''} // Show item label next to the checkbox
                        onChange={handleCheckboxChange}
                        disabled={hasSubmitted}
                        checked={value === valueOn}
                    />
                    {enableLabel && caption && (
                        <CaptionInput caption={caption} />
                    )}
                </Grid>
            </Grid>
        </FormControl>
    );
};

export default CheckboxInput;
