import React from 'react';
import { Box } from '@mui/material';
import { links } from '../../../helpers/Links';
import TopAppBarItemButtonLink from './TopAppBarItemButtonLink';
import { User } from '../../../workers/ApiWorker';

interface TopAppBarProps {
    isMobile: boolean;
    user?: User;
}

const TopAppBarItems: React.FC<TopAppBarProps> = ({ isMobile, user }) => {
    const sidebarItems = links(isMobile);

    return (
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 2 }}>
            {sidebarItems.map((item) => {
                // Check if item should be rendered
                if (!item.sideBar) return null; // Explicitly return null if item.sideBar is false
                if (item.name === 'Upload' && !user) return null; // Check for Upload item

                // Render the TopAppBarItemButtonLink component
                return (
                    <TopAppBarItemButtonLink
                        key={item.name}
                        icon={item.icon}
                        text={item.name.toLowerCase()}
                        link={item.path}
                    />
                );
            })}
        </Box>
    );
};

export default TopAppBarItems;
