// CreateUploadPage.tsx
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
    Button,
    CardContent,
    Fade,
    Step,
    StepContent,
    StepIconProps,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import { useCustomTheme } from '../contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../contexts/ApiContext';
import Card from '@mui/material/Card';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Info, Image, CheckCircle } from '@mui/icons-material';
import {
    CreateUploadBasicDetailsProps,
    CreateUploadFinalizeProps,
    CreateUploadProps,
    Game,
    UploadType,
    User,
} from '../workers/ApiWorker';
import TextInput from '../components/universal/inputs/TextInput';
import SelectInput from '../components/universal/inputs/SelectInput';
import MultiSelectInput from '../components/universal/inputs/MultiSelectInput';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import ImageUpload from '../components/universal/images/ImageUploads';
import axios from 'axios';
import { unknownError } from '../helpers/ApiResponses';
import ErrorAlert from '../components/universal/alerts/ErrorAlert';
import SuccessAlert from '../components/universal/alerts/SuccessAlert';
import TagInput from '../components/universal/inputs/TagInput';
import { fadeTimeout, getOverallPadding } from '../helpers/Themes';
import PageTopSection from '../components/universal/pageTopSection/PageTopSection';
import RichTextInput from '../components/universal/inputs/RichTextInput';
import CheckboxInput from '../components/universal/inputs/CheckboxInput';

interface CreateUploadPageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
}

interface StepProps {
    label: string;
    info: string;
    icon: JSX.Element;
    content: JSX.Element;
}

// Define validation schema using Yup
const validationSchemaBasicDetails = Yup.object({
    name: Yup.string()
        .required('Title is required')
        .max(25, 'Caption must be at most 25 characters'),
    uploadType: Yup.string().required('Category is required'),
    videoUrl: Yup.string().matches(
        /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/,
        'Must be a valid YouTube URL (starting with http or https, domain youtube.com or youtu.be)'
    ), // Ensures it starts with http/https and matches YouTube domains
    miniDescription: Yup.string()
        .required('Caption is required')
        .max(45, 'Caption must be at most 45 characters'),
    description: Yup.string().required('Upload Description is required'),
    games: Yup.array().min(1, 'At least one Game is required'),
});

// Define validation schema using Yup
const validationSchemaFinalizeDetails = Yup.object({
    tags: Yup.array().min(1, 'At least one Tag is required'),
    downloadLink: Yup.string()
        .url('Download link must be a valid URL') // Ensures any valid URL
        .required('Download Link is required'),
    drm: Yup.boolean().required('DRM is required'),
});

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

const CreateUploadPage: React.FC<CreateUploadPageProps> = ({
    isMobile,
    padding,
    user,
}) => {
    const apiWorker = useWorker(createApiWorker);

    const defaultCountdown = 3;

    const [error, setError] = React.useState<string | undefined>(undefined);
    const [success, setSuccess] = React.useState<string | undefined>(undefined);

    const [loadingGames, setLoadingGames] = useState<boolean>(true);
    const [games, setGames] = useState<Game[] | null>(null);

    const { theme } = useCustomTheme();
    const { token, uploadTypes, logout } = useApi();
    const navigate = useNavigate();

    const [uploadImage, setUploadImage] = useState<File | undefined>(undefined);

    const uploadDataBasicDetailsDefault: CreateUploadBasicDetailsProps = {
        name: '',
        uploadType: '',
        miniDescription: '',
        description: '',
        games: [],
        videoUrl: undefined,
    };
    const [uploadDataBasicDetails, setUploadDataBasicDetails] =
        React.useState<CreateUploadBasicDetailsProps>(
            uploadDataBasicDetailsDefault
        );

    const uploadDataFinalizeDetailsDefault: CreateUploadFinalizeProps = {
        downloadLink: '',
        drm: false,
        tags: [],
        additionalTypeInformation: undefined,
        nsfw: false,
    };
    const [uploadDataFinalizeDetails, setUploadDataFinalizeDetails] =
        React.useState<CreateUploadFinalizeProps>(
            uploadDataFinalizeDetailsDefault
        );

    const [foundGames, setFoundGames] = useState<Game[]>([]); // State to hold found Game objects
    const [foundType, setFoundType] = useState<UploadType | undefined>(
        undefined
    ); // State for found type

    const [allDataSubmitted, setAllDataSubmitted] = useState(false);

    useEffect(() => {
        // Only attempt to find games if they are loaded and uploadDataBasicDetails.games is not empty
        if (games && uploadDataBasicDetails.games.length > 0) {
            // Find all games that match the UUIDs in uploadDataBasicDetails.games
            const matchedGames = uploadDataBasicDetails.games
                .map((uuid) => games.find((game) => game.uuid === uuid))
                .filter(Boolean) as Game[]; // Filter out any undefined values

            setFoundGames(matchedGames); // Set the found games
        }
    }, [games, uploadDataBasicDetails.games]);

    useEffect(() => {
        // Check if uploadTypes is not null and then find the Type
        if (uploadTypes) {
            const type = uploadTypes.find(
                (type) => type.uuid === uploadDataBasicDetails.uploadType
            );
            setFoundType(type ? type : undefined); // Set found type name or empty string if not found
        }
    }, [uploadTypes, uploadDataBasicDetails.uploadType]); // Re-run when uploadTypes or uploadData.type changes

    useEffect(() => {
        const getGames = async () => {
            setLoadingGames(true);
            const response = await apiWorker.getGames();
            setGames(response.data);
            setLoadingGames(false);
        };
        getGames().catch(console.error);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleImageUpload = async (value?: File) => {
        setUploadImage(value);
    };

    // Custom StepIcon component to create the blue circle with the white icon
    const CustomStepIcon: React.FC<
        StepIconProps & { iconElement: JSX.Element }
    > = ({ iconElement, active, completed, className }) => {
        return (
            <Box
                className={className} // This ensures default MUI styling (active/completed states)
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 34, // Circle width
                    height: 34, // Circle height
                    borderRadius: '50%', // Ensures the icon is circular
                    backgroundColor:
                        active || completed
                            ? theme.palette.primary.main
                            : theme.palette.primary.main,
                    position: 'relative',
                    marginLeft: -0.55, // Adjust margin to align with the step line
                }}
            >
                {iconElement}
            </Box>
        );
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = (values?: CreateUploadFinalizeProps) => {
        if (activeStep === 2) {
            if (values) {
                setUploadDataFinalizeDetails(values);
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const steps: StepProps[] = [
        {
            label: 'BASIC DETAILS',
            info: 'Essential information required for your submission to Discovery',
            icon: <Info />,
            content: (
                <>
                    {uploadTypes !== null && games !== null ? (
                        <Formik
                            initialValues={uploadDataBasicDetails}
                            validationSchema={validationSchemaBasicDetails}
                            onSubmit={async (values, { resetForm }) => {
                                if (values.videoUrl === '') {
                                    values.videoUrl = undefined;
                                }
                                setUploadDataBasicDetails(values);
                                handleNext();
                            }}
                        >
                            {({
                                errors,
                                touched,
                                values,
                                handleChange,
                                isSubmitting,
                                handleBlur,
                                isValid,
                                setFieldValue,
                            }) => (
                                <Form>
                                    <SelectInput
                                        items={uploadTypes?.map((item) => ({
                                            id: item.uuid,
                                            value: item.uuid,
                                            name: item.name,
                                        }))}
                                        id={'uploadType'}
                                        label={'CATEGORY *'}
                                        value={values.uploadType}
                                        errors={errors.uploadType}
                                        touched={touched.uploadType}
                                        handleBlur={handleBlur}
                                        hasSubmitted={allDataSubmitted}
                                        handleChange={setFieldValue}
                                        isMobile={isMobile}
                                        sortByName={true}
                                    />

                                    <MultiSelectInput
                                        items={games?.map((item) => ({
                                            id: item.uuid,
                                            value: item.uuid,
                                            name: item.name,
                                        }))}
                                        id={'games'}
                                        label={'GAME(S) *'}
                                        value={values.games}
                                        errors={errors.games}
                                        touched={touched.games}
                                        handleBlur={handleBlur}
                                        hasSubmitted={allDataSubmitted}
                                        handleChange={setFieldValue}
                                        isMobile={isMobile}
                                        sortByName={true}
                                    />

                                    <TextInput
                                        id={'name'}
                                        label={'TITLE *'}
                                        value={values.name}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        hasSubmitted={allDataSubmitted}
                                        touched={touched.name}
                                        errors={errors.name}
                                        isMobile={isMobile}
                                    />

                                    <TextInput
                                        id={'miniDescription'}
                                        label={'CAPTION *'}
                                        value={values.miniDescription}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        hasSubmitted={allDataSubmitted}
                                        touched={touched.miniDescription}
                                        errors={errors.miniDescription}
                                        isMobile={isMobile}
                                        caption="The descriptive text visible in the thumbnail of the upload"
                                    />

                                    <RichTextInput
                                        id={'description'}
                                        label={'UPLOAD DESCRIPTION *'}
                                        value={values.description}
                                        errors={errors.description}
                                        handleBlur={handleBlur}
                                        hasSubmitted={allDataSubmitted}
                                        touchedFormik={touched.description}
                                        handleChange={setFieldValue}
                                        isMobile={isMobile}
                                    />

                                    <TextInput
                                        id={'videoUrl'}
                                        label={'YOUTUBE'}
                                        value={values.videoUrl}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        hasSubmitted={allDataSubmitted}
                                        touched={touched.videoUrl}
                                        errors={errors.videoUrl}
                                        isMobile={isMobile}
                                    />

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginTop: '1rem',
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            disabled={isSubmitting || !isValid}
                                            variant="contained"
                                            sx={{
                                                padding: '0.8rem',
                                            }}
                                        >
                                            CONTINUE
                                        </Button>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    ) : null}
                </>
            ),
        },
        {
            label: 'MEDIA',
            info: 'Visual content for your submission. Accepted formats include JPEG, PNG, and GIF files',
            icon: <Image />,
            content: (
                <>
                    <ImageUpload
                        uploadData={{
                            title: uploadDataBasicDetails.name,
                            description: uploadDataBasicDetails.miniDescription,
                            type: foundType,
                            games: foundGames,
                        }}
                        file={uploadImage}
                        handleImageUpload={handleImageUpload}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '1rem',
                        }}
                    >
                        <Button
                            type="button"
                            disabled={!uploadImage}
                            variant="contained"
                            onClick={() => handleNext()}
                            sx={{
                                padding: '0.8rem',
                                marginLeft: 2,
                            }}
                        >
                            CONTINUE
                        </Button>
                        <Button
                            type="button"
                            variant="outlined"
                            color="info"
                            sx={{
                                padding: '0.8rem',
                                marginLeft: 2,
                            }}
                            onClick={() => handleBack()}
                        >
                            BACK
                        </Button>
                    </Box>
                </>
            ),
        },
        {
            label: 'FINALIZE',
            info: 'The concluding configurations required to finalize your submission for Discovery',
            icon: <CheckCircle />,
            content: (
                <Formik
                    initialValues={uploadDataFinalizeDetails}
                    validationSchema={validationSchemaFinalizeDetails}
                    onSubmit={async (values, { resetForm }) => {
                        setAllDataSubmitted(true);
                        setSuccess('');
                        setError('');
                        setUploadDataFinalizeDetails(values);

                        if (token) {
                            let createUpload = undefined;

                            try {
                                const uploadData: CreateUploadProps = {
                                    ...values,
                                    ...uploadDataBasicDetails,
                                };

                                createUpload = await apiWorker.postUpload(
                                    uploadData,
                                    token,
                                    foundType
                                );
                            } catch (error) {
                                if (axios.isAxiosError(error)) {
                                    if (error.response?.data) {
                                        setError(error.response?.data.message);
                                    } else {
                                        setError(error.message);
                                    }
                                } else {
                                    setError(unknownError().message);
                                }
                            }

                            if (createUpload !== undefined) {
                                try {
                                    if (uploadImage) {
                                        await apiWorker.postImage(
                                            {
                                                uuid: createUpload.data.uuid,
                                                type: 'main',
                                                image: uploadImage,
                                            },
                                            token
                                        );
                                    }

                                    navigate(
                                        '/uploads/' + createUpload.data.uuid
                                    );
                                } catch (error) {
                                    if (axios.isAxiosError(error)) {
                                        if (error.response?.data) {
                                            setError(
                                                error.response?.data.message
                                            );
                                        } else {
                                            setError(error.message);
                                        }
                                    } else {
                                        setError(unknownError().message);
                                    }

                                    // TODO - Delete the mod if fails
                                }
                            }

                            setAllDataSubmitted(false);
                        } else {
                            let countdownTimer = defaultCountdown; // Initialize countdown with 3 seconds

                            // Set initial error message with the countdown
                            setError(
                                `You have been logged out, you will be redirected to the login page in ${countdownTimer} seconds!`
                            );

                            // Start the countdown timer
                            const timer = setInterval(() => {
                                countdownTimer -= 1;

                                // Update error message with the new countdown value
                                setError(
                                    `You have been logged out, you will be redirected to the login page in ${countdownTimer} seconds!`
                                );

                                // If countdown reaches 0, redirect and clear the timer
                                if (countdownTimer === 0) {
                                    clearInterval(timer);
                                    logout('/login');
                                }
                            }, 1000); // 1000ms = 1 second

                            return; // Stop further code execution after redirect
                        }
                    }}
                >
                    {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        isSubmitting,
                        handleBlur,
                        isValid,
                        setFieldValue,
                    }) => (
                        <Form>
                            // TODO - Turned off for now until further
                            conversation on how it should work
                            {/*<CheckboxInput*/}
                            {/*    id={'nsfw'}*/}
                            {/*    label={'NSFW'}*/}
                            {/*    valueOn={true}*/}
                            {/*    valueOff={false}*/}
                            {/*    enableLabel={false}*/}
                            {/*    value={values.nsfw}*/}
                            {/*    handleChange={setFieldValue}*/}
                            {/*    hasSubmitted={allDataSubmitted}*/}
                            {/*    isMobile={isMobile}*/}
                            {/*/>*/}
                            {foundType?.name === 'character' ||
                            foundType?.name === 'character data' ? (
                                <CheckboxInput
                                    id={'additionalTypeInformation'}
                                    label={'WOMEN'}
                                    valueOn={'women'}
                                    valueOff={undefined}
                                    enableLabel={false}
                                    value={values.additionalTypeInformation}
                                    handleChange={setFieldValue}
                                    hasSubmitted={allDataSubmitted}
                                    isMobile={isMobile}
                                />
                            ) : null}
                            <TextInput
                                id={'downloadLink'}
                                label={'DOWNLOAD LINK *'}
                                value={values.downloadLink}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                hasSubmitted={allDataSubmitted}
                                touched={touched.downloadLink}
                                errors={errors.downloadLink}
                                isMobile={isMobile}
                            />
                            <TagInput
                                id={'tags'}
                                label={'TAGS *'}
                                value={values.tags}
                                handleChange={setFieldValue}
                                handleBlur={handleBlur}
                                hasSubmitted={allDataSubmitted}
                                touched={touched.tags}
                                errors={errors.tags}
                                isMobile={isMobile}
                                caption="Press Enter or Comma to add a tag."
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '1rem',
                                }}
                            >
                                <Button
                                    type="submit"
                                    disabled={isSubmitting || !isValid}
                                    variant="contained"
                                    sx={{
                                        padding: '0.8rem',
                                    }}
                                >
                                    UPLOAD
                                </Button>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="info"
                                    sx={{
                                        padding: '0.8rem',
                                        marginLeft: 2,
                                    }}
                                    onClick={() => handleBack(values)} // Pass values to handleBack
                                >
                                    BACK
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            ),
        },
    ];

    const [activeStep, setActiveStep] = React.useState(0);

    const topSectionInfo = (
        <>
            Before submitting content to <b>Discovery</b>, please ensure that
            you have read the&nbsp;
            <Typography
                component="span"
                variant="caption"
                sx={{ color: theme.palette.warning.main }}
            >
                terms and conditions&nbsp;
            </Typography>
        </>
    );

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                <PageTopSection
                    isMobile={isMobile}
                    padding={padding}
                    simpleContent={{
                        title: 'UPLOAD',
                        info: topSectionInfo,
                    }}
                />

                {/* PAGE CONTENTS */}
                <Box
                    sx={{
                        paddingLeft: getOverallPadding(isMobile, padding),
                        paddingRight: getOverallPadding(isMobile, padding),
                        marginBottom: isMobile ? 10 : 3,
                    }}
                >
                    {loadingGames ? null : (
                        <>
                            {error && <ErrorAlert message={error}></ErrorAlert>}

                            {success && (
                                <SuccessAlert message={success}></SuccessAlert>
                            )}
                            <Stepper
                                activeStep={activeStep}
                                orientation="vertical"
                            >
                                {steps.map((step, index) => (
                                    <Step key={step.label}>
                                        <StepLabel
                                            StepIconComponent={(props) => (
                                                <CustomStepIcon
                                                    {...props}
                                                    iconElement={step.icon}
                                                />
                                            )}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: isMobile
                                                        ? 'column'
                                                        : 'row', // Change direction based on mobile view
                                                    alignItems: isMobile
                                                        ? 'flex-start'
                                                        : 'center', // Align items differently on mobile
                                                    paddingLeft: !isMobile
                                                        ? 2
                                                        : 0,
                                                }}
                                            >
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        color: theme.palette
                                                            .text.primary,
                                                    }}
                                                >
                                                    {step.label}
                                                </Typography>
                                                {/* Add step.info below the label in mobile view */}
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        marginLeft: isMobile
                                                            ? 0.1
                                                            : 1, // No left margin in mobile view
                                                        color: theme.palette
                                                            .text.secondary, // Keep the secondary color
                                                    }}
                                                >
                                                    {step.info}
                                                </Typography>
                                            </Box>
                                        </StepLabel>

                                        <StepContent
                                            sx={{
                                                paddingLeft: !isMobile
                                                    ? 5
                                                    : 3.2,
                                            }}
                                        >
                                            <Card>
                                                <CardContent>
                                                    {step.content}
                                                </CardContent>
                                            </Card>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </>
                    )}
                </Box>
            </Box>
        </Fade>
    );
};

export default CreateUploadPage;
