// HomePage.tsx
import React, { useEffect } from 'react';
import { Fab, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useCustomTheme } from '../../contexts/ThemeContext';
import { UploadMember, UploadPublic, User } from '../../workers/ApiWorker';
import { Download, Favorite } from '@mui/icons-material';
import { useApi } from '../../contexts/ApiContext';

interface CoreInformationProps {
    upload: UploadPublic | UploadMember;
    user?: User;
}

const DownloadAndHearts: React.FC<CoreInformationProps> = ({
    upload,
    user,
}) => {
    const { token } = useApi();
    const { theme } = useCustomTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: {
                    md: 'row', // Align the icons horizontally (side by side)
                    lg: 'row', // Keep them side by side even on large screens
                },
                justifyContent: {
                    md: 'flex-start', // Align left for smaller screens
                    lg: 'flex-end', // Align right for large screens
                },
                alignItems: 'center', // Vertically center the icons
                gap: 2, // Add space between the heart/number box and the download button
                marginTop: 2.5,
            }}
        >
            {/* Box for Heart icon and number */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5, // Adjust this value to control the space between the heart and the number
                }}
            >
                <Favorite />
                <Typography
                    variant="subtitle1"
                    sx={{ color: theme.palette.text.primary }}
                >
                    {upload.stats.hearts}
                </Typography>
            </Box>

            {token && user ? (
                <Fab color="primary" aria-label="download">
                    <Download />
                </Fab>
            ) : null}
        </Box>
    );
};

export default DownloadAndHearts;
