// CustomModal.tsx
import React from 'react';
import { Modal, Fade, Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface CustomModalProps {
    children?: React.ReactNode; // Content inside the modal (e.g. text, form, etc.)
    isOpen: boolean;
    onClose: () => void;
    cancelLabel?: string; // Label for the cancel button
    confirmLabel?: string; // Label for the confirm button
    onConfirm?: () => void; // Optional onConfirm handler for the "Confirm" button
    isSubmitting?: boolean;
    isValid?: boolean;
}

const CustomModal: React.FC<CustomModalProps> = ({
    children,
    isOpen,
    onClose,
    cancelLabel = 'Cancel', // Default cancel label
    confirmLabel = 'Confirm', // Default confirm label
    onConfirm, // Optional onConfirm handler
    isSubmitting,
    isValid,
}) => {
    const theme = useTheme();

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                if (!isSubmitting) onClose();
            }}
        >
            <Fade in={isOpen}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        bgcolor: theme.palette.secondary.light,
                        borderRadius: 1,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    {/* Modal Content */}
                    {children}

                    {/* Render custom buttons directly, or if none, provide Cancel and Confirm buttons */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginTop: '15px',
                        }}
                    >
                        {onConfirm && ( // Only show the "Confirm" button if onConfirm is provided
                            <Button
                                disabled={isSubmitting || !isValid}
                                onClick={() => {
                                    onConfirm();
                                }}
                                variant="contained"
                                sx={{ mr: 2 }}
                            >
                                {confirmLabel}
                            </Button>
                        )}
                        <Button
                            disabled={isSubmitting}
                            onClick={onClose}
                            color="primary"
                            variant="outlined"
                        >
                            {cancelLabel}
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default CustomModal;
