// ViewUserPage.tsx
import React, { useEffect, useState } from 'react';
import { fadeTimeout, getOverallPadding } from '../helpers/Themes';
import { Fade, Grid, Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import NoResultsFound from '../components/universal/loader/NoResultsFound';
import { useApi } from '../contexts/ApiContext';
import { User } from '../workers/ApiWorker';
import Box from '@mui/material/Box';
import { Block } from '@mui/icons-material';
import CardMedia from '@mui/material/CardMedia';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';

interface ViewUserPageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
}

const ViewUserPage: React.FC<ViewUserPageProps> = ({
    isMobile,
    padding,
    user,
}) => {
    const theme = useTheme();
    const location = useLocation();
    const { username } = useParams<{ username: string }>(); // Extract uuid from URL
    const { userProfileUuid } = location.state || {};

    const { token } = useApi();

    const [noResultsFound, setNoResultsFound] = useState<boolean>(false);
    const [loadingUser, setLoadingUser] = useState<boolean>(true);

    const [loadedUser, setLoadedUser] = useState<User | null>(null);

    useEffect(() => {
        const getUser = async () => {
            alert('ENDPOINT - get single');
        };

        const getUsers = async () => {
            alert('ENDPOINT - get all');
        };

        const getUserForPage = async () => {
            if (token && user && user.userProfile.uuid === userProfileUuid) {
                setLoadedUser(user);
                setLoadingUser(false);
            } else {
                if (userProfileUuid) {
                    console.log('get by uuid');
                } else {
                    if (username) {
                        console.log(
                            'get all then filter after maybe, but search by username'
                        );
                    } else {
                        setNoResultsFound(true);
                    }
                }
            }
        };

        getUserForPage()
            .then(() => {})
            .catch((error) => {
                console.log('Error fetching user:', error);
            });
    }, []);

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <div>
                <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                    {noResultsFound ? (
                        <NoResultsFound />
                    ) : (
                        !loadingUser &&
                        loadedUser && (
                            <>
                                {/* Rest of the content remains unchanged */}
                                <Box
                                    sx={{
                                        paddingLeft: getOverallPadding(
                                            isMobile,
                                            padding
                                        ),
                                        paddingRight: getOverallPadding(
                                            isMobile,
                                            padding
                                        ),
                                    }}
                                >
                                    <Grid container spacing={4}>
                                        <Grid
                                            item
                                            xs={12} // Take full width on extra-small screens
                                            sm={12} // Full width on small screens
                                            md={3} // Fixed width for medium screens
                                            lg={2.5} // Adjusted width for large screens
                                            xl={2} // Further adjust for extra-large screens
                                            sx={{
                                                display: isMobile
                                                    ? 'flex'
                                                    : 'block',
                                                justifyContent: isMobile
                                                    ? 'center'
                                                    : 'unset',
                                                alignItems: isMobile
                                                    ? 'center'
                                                    : 'unset',
                                            }}
                                        >
                                            <Card
                                                sx={{
                                                    width: {
                                                        xs: '80%', // Adjust card width on small screens
                                                        sm: '60%', // Card width for small screens
                                                        md: 212, // Fixed width for medium and larger screens
                                                    },
                                                    position: 'relative',
                                                    '&::before': {
                                                        content: '""',
                                                        display: 'block',
                                                        paddingTop: '100%', // Maintain 1:1 aspect ratio
                                                    },
                                                }}
                                            >
                                                <CardMedia
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                            height: '100%',
                                                        }}
                                                    >
                                                        <Block
                                                            sx={{
                                                                fontSize: 60,
                                                                color: theme
                                                                    .palette
                                                                    .error.main,
                                                            }}
                                                        />
                                                        <Typography
                                                            variant="h2"
                                                            sx={{
                                                                marginTop: 1,
                                                            }}
                                                        >
                                                            NO IMAGE
                                                        </Typography>
                                                    </Box>
                                                </CardMedia>
                                            </Card>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12} // Take full width on extra-small screens
                                            sm={12} // Full width on small screens
                                            md={9} // Take 9/12 width on medium screens
                                            lg={9.5} // Adjust width on large screens
                                            xl={9.8} // Further adjust width for extra-large screens
                                            sx={{
                                                textAlign: {
                                                    xs: 'center',
                                                    sm: 'center',
                                                    md: 'left',
                                                },
                                                padding: {
                                                    xs: '16px',
                                                    sm: '32px',
                                                }, // Add padding for smaller screens to prevent overlap
                                            }}
                                        >
                                            <Typography variant={'h2'}>
                                                {loadedUser.userProfile.username.toUpperCase()}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        )
                    )}
                </Box>
            </div>
        </Fade>
    );
};

export default ViewUserPage;
