import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';

// Function to check if an asterisk is present at the end of the label
export const isAsteriskPresent = (input: string): boolean => {
    return input.endsWith('*');
};

// Function to get the main label without the asterisk
export const mainLabel = (input: string): string => {
    return isAsteriskPresent(input) ? input.slice(0, -1).trim() : input;
};

export const ReturnLabel = (input: string, isMobile: boolean): JSX.Element => {
    const theme = useTheme(); // Using theme for default border if necessary

    return (
        <Grid item xs={isMobile ? 12 : 3}>
            <Typography
                variant="h6"
                sx={{ display: 'flex', alignItems: 'center' }}
            >
                <span style={{ color: theme.palette.text.primary }}>
                    {mainLabel(input)}
                </span>
                {isAsteriskPresent(input) && (
                    <span
                        style={{
                            color: theme.palette.error.main,
                            marginLeft: '4px',
                        }}
                    >
                        *
                    </span> // Red asterisk
                )}
            </Typography>
        </Grid>
    );
};
