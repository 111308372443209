export interface AxiosStandardErrorResponse {
    status?: number;
    message: string;
}

export interface UnprocessableEntityErrorResponse
    extends AxiosStandardErrorResponse {
    errors?: object;
}

export const unknownError = (): AxiosStandardErrorResponse => {
    return {
        status: 500,
        message: 'Unknown Error',
    };
};
