// Themes.ts
import type {} from '@mui/x-data-grid/themeAugmentation';

export const SearchCardSizeOthers = {
    '@media (max-width: 750px)': {
        flexBasis: '100%',
        maxWidth: '100%',
    },
    '@media (min-width: 750px) and (max-width: 900px)': {
        flexBasis: '50%',
        maxWidth: '50%',
    },
    '@media (min-width: 900px) and (max-width: 1200px)': {
        flexBasis: '100%',
        maxWidth: '100%',
    },
    '@media (min-width: 1200px) and (max-width: 1445px)': {
        flexBasis: '50%',
        maxWidth: '50%',
    },
    '@media (min-width: 1445px) and (max-width: 1940px)': {
        flexBasis: '33%',
        maxWidth: '33%',
    },
    '@media (min-width: 1940px)': {
        flexBasis: '25%',
        maxWidth: '25%',
    },
};

export const PreviewCardSizeOthers = {
    '@media (max-width: 750px)': {
        flexBasis: '100%',
        maxWidth: '100%',
    },
    '@media (min-width: 750px) and (max-width: 900px)': {
        flexBasis: '50%',
        maxWidth: '50%',
    },
    '@media (min-width: 900px) and (max-width: 1020px)': {
        flexBasis: '50%',
        maxWidth: '50%',
    },
    '@media (min-width: 1020px) and (max-width: 1220px)': {
        flexBasis: '33%',
        maxWidth: '33%',
    },
    '@media (min-width: 1220px) and (max-width: 1340px)': {
        flexBasis: '33%',
        maxWidth: '33%',
    },
    '@media (min-width: 1340px) and (max-width: 1810px)': {
        flexBasis: '25%',
        maxWidth: '25%',
    },
    '@media (min-width: 1810px) and (max-width: 2335px)': {
        flexBasis: '16.6%',
        maxWidth: '16.6%',
    },
    '@media (min-width: 2335px)': {
        flexBasis: '16.6%',
        maxWidth: '16.6%',
    },
};

export const PreviewCardSizeArenas = {
    '@media (max-width: 750px)': {
        flexBasis: '100%',
        maxWidth: '100%',
    },
    '@media (min-width: 750px) and (max-width: 900px)': {
        flexBasis: '100%',
        maxWidth: '100%',
    },
    '@media (min-width: 900px) and (max-width: 1020px)': {
        flexBasis: '50%',
        maxWidth: '50%',
    },
    '@media (min-width: 1020px) and (max-width: 1220px)': {
        flexBasis: '50%',
        maxWidth: '50%',
    },
    '@media (min-width: 1220px) and (max-width: 1340px)': {
        flexBasis: '50%',
        maxWidth: '50%',
    },
    '@media (min-width: 1340px) and (max-width: 1810px)': {
        flexBasis: '33%',
        maxWidth: '33%',
    },
    '@media (min-width: 1810px) and (max-width: 2335px)': {
        flexBasis: '33%',
        maxWidth: '33%',
    },
    '@media (min-width: 2335px)': {
        flexBasis: '25%',
        maxWidth: '25%',
    },
};
