import React, { createContext, FC, useContext, useState } from 'react';
import { discoveryBlue } from '../helpers/Themes';
import { Theme } from '@mui/material/styles';

interface ThemeContextProps {
    theme: Theme;
    themeType: string;
}

const defaultTheme = discoveryBlue;

const ThemeContext = createContext<ThemeContextProps>(defaultTheme);

interface ThemeProviderProps {
    children: React.ReactNode;
}

export const CustomThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
    const [theme] = useState<Theme>(defaultTheme.theme);
    const [themeType] = useState<string>(defaultTheme.themeType);

    return (
        <ThemeContext.Provider value={{ theme, themeType }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useCustomTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within an ThemeProvider');
    }
    return context;
};
