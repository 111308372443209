import React, {
    createContext,
    FC,
    useContext,
    useEffect,
    useState,
} from 'react';
import Cookies from 'universal-cookie';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { UploadType, User } from '../workers/ApiWorker';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';

interface ApiContextProps {
    uploadTypes: UploadType[] | null;
    token: string | undefined;
    getTokenAndStoreUser: () => Promise<string | undefined>;
    storeTokenAndUser: (jwtToken: string) => void;
    logout: (redirect?: string) => void;
    user: User | null;
    storeUser: () => Promise<User | null>;
}

// Define a custom type that extends JwtPayload
export interface CustomJwtPayload extends JwtPayload {
    user?: User;
}

const ApiContext = createContext<ApiContextProps | undefined>(undefined);

interface ApiProviderProps {
    children: React.ReactNode;
}

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

export const ApiProvider: FC<ApiProviderProps> = ({ children }) => {
    const apiWorker = useWorker(createApiWorker);

    const navigate = useNavigate();

    const cookies = new Cookies();

    const location = useLocation(); // Get the current route

    const [uploadTypes, setUploadTypes] = useState<UploadType[] | null>(null);
    const [token, setToken] = useState<string | undefined>(
        cookies.get('token')
    );
    const [user, setUser] = useState<User | null>(null);

    // Used to get the most up to date user details
    const storeUser = async (): Promise<User | null> => {
        if (token) {
            try {
                const response = await apiWorker.getUserMe(token);
                setUser(response.data);
                return response.data;
            } catch (error) {
                console.error(
                    'Error trying to get the user from token upload types - getUser',
                    error
                );
            }
        }
        return null;
    };

    // This is used on the useEffect on this page, it is triggered every time a page loads
    const getTokenAndStoreUser = async (): Promise<string | undefined> => {
        if (token) {
            const decodedToken = jwtDecode(token) as CustomJwtPayload;
            // We need to check if expired etc
            // Get the current timestamp in seconds
            const currentTimestamp = Math.floor(Date.now() / 1000);

            if (decodedToken.exp) {
                if (decodedToken.exp > currentTimestamp) {
                    //      await storeUser();

                    return token;
                } else {
                    logout().catch(console.error);
                }
            } else {
                logout().catch(console.error);
            }
        }
        return undefined;
    };

    // This is used on the Login and Register Page
    const storeTokenAndUser = async (jwtToken: string) => {
        setToken(jwtToken);
        const decodedToken = jwtDecode(jwtToken) as CustomJwtPayload;

        if (decodedToken.exp) {
            // Convert the expiration time to milliseconds
            const expirationTimeInMilliseconds = decodedToken.exp * 1000;

            // Set the token and expiration in cookies
            cookies.set('token', jwtToken, {
                path: '/',
                expires: new Date(expirationTimeInMilliseconds),
            });

            await storeUser();
        } else {
            // Handle the case where the token does not have an expiration time
            console.warn('Token does not have an expiration time');
        }
    };

    const logout = async (redirect?: string) => {
        // go to the api and logout
        if (!token) return;
        await apiWorker.logout(token);
        cookies.remove('token');
        setToken(undefined);
        setUser(null);

        if (redirect) {
            navigate(redirect);
        } else {
            window.location.reload(); // Refresh the current page
        }
    };

    // This is triggered on every page
    useEffect(() => {
        getTokenAndStoreUser().catch(console.error);

        const fetchUploadTypes = async () => {
            try {
                const response = await apiWorker.getUploadTypes();
                const expirationDate = new Date();
                expirationDate.setTime(
                    expirationDate.getTime() + 48 * 60 * 60 * 1000
                ); // 48 hours from now

                setUploadTypes(response.data);
                cookies.set('uploadTypes', response.data, {
                    path: '/',
                    expires: expirationDate,
                });
            } catch (error) {
                console.error('Error getting upload types', error);
                cookies.remove('uploadTypes');
            }
        };

        const storedUploadTypes = cookies.get('uploadTypes');

        if (!storedUploadTypes) {
            fetchUploadTypes().catch(console.error);
        } else {
            setUploadTypes(storedUploadTypes);
        }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    // This was the original one of the above, commented out for reference for now
    /**
    useEffect(() => {
        getTokenAndStoreUser().catch(console.error);

        const fetchUploadTypes = async () => {
            try {
                const response = await apiWorker.getUploadTypes();
                const expirationDate = new Date();
                expirationDate.setTime(
                    expirationDate.getTime() + 48 * 60 * 60 * 1000
                ); // 48 hours from now

                setUploadTypes(response.data);
                cookies.set('uploadTypes', response.data, {
                    path: '/',
                    expires: expirationDate,
                });
            } catch (error) {
                console.error('Error getting upload types', error);
                cookies.remove('uploadTypes');
            }
        };

        const storedUploadTypes = cookies.get('uploadTypes');

        if (!storedUploadTypes) {
            fetchUploadTypes().catch(console.error);
        } else {
            setUploadTypes(storedUploadTypes);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
        **/

    return (
        <ApiContext.Provider
            value={{
                uploadTypes,
                token,
                getTokenAndStoreUser,
                storeTokenAndUser,
                logout,
                user,
                storeUser,
            }}
        >
            {children}
        </ApiContext.Provider>
    );
};

export const useApi = () => {
    const context = useContext(ApiContext);
    if (!context) {
        throw new Error('useApi must be used within an AuthProvider');
    }
    return context;
};
