// ErrorAlert.tsx
import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

interface ErrorAlertInputProps {
    message: string;
}

const ErrorAlert: React.FC<ErrorAlertInputProps> = ({ message }) => {
    return (
        <Box sx={{ width: '100%', marginBottom: 2 }}>
            <Alert severity="error" variant="outlined">
                {message}
            </Alert>
        </Box>
    );
};

export default ErrorAlert;
