// UserAvatar.tsx
import React from 'react';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import { UserProfile } from '../../workers/ApiWorker';
import { useNavigate } from 'react-router-dom'; // Assuming you're using MUI's theme

interface UserAvatarProps {
    backup: string;
    dimensions?: number;
    marginRight?: number;
    padding?: number;
    image?: string;
    border?: boolean;
    key?: number;
    total?: boolean;
    userProfile?: UserProfile;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
    backup,
    dimensions,
    marginRight,
    padding,
    image,
    border,
    key,
    total,
    userProfile,
}) => {
    const theme = useTheme(); // Using theme for default border if necessary

    const navigate = useNavigate();

    return (
        <Avatar
            key={key}
            src={image} // If `image` is provided, it will be used as the source of the avatar
            sx={{
                width: dimensions ? dimensions : undefined, // If dimensions provided, use it, else default to 36
                height: dimensions ? dimensions : undefined, // Same for height
                border: border
                    ? `2px solid ${theme.palette.primary.main}`
                    : undefined,
                padding: padding ? `${padding}px` : undefined, // If padding is provided, apply it
                mr: marginRight ? marginRight : undefined, // If marginRight is provided, apply it
                color: theme.palette.text.primary,
                cursor: userProfile ? 'pointer' : undefined, // Show pointer cursor when clickable
            }}
            onClick={() => {
                if (userProfile) {
                    const userProfileUuid = userProfile.uuid;
                    navigate(`/${userProfile.username}`, {
                        state: { userProfileUuid },
                    }); // Navigate only if userProfile exists
                }
            }}
        >
            {total ? backup : !image ? backup.slice(0, 1).toUpperCase() : null}
        </Avatar>
    );
};

export default UserAvatar;
