import { FaBoltLightning } from 'react-icons/fa6';
import {
    AutoFixHigh,
    Celebration,
    Moving,
    Timelapse,
} from '@mui/icons-material';

export const searchFilters = () => {
    return [
        { text: 'Latest', value: 'latest', icon: <FaBoltLightning /> },
        { text: 'Trending', value: 'trending', icon: <Moving /> },
        { text: 'Random', value: 'random', icon: <AutoFixHigh /> },
        { text: 'Popular', value: 'popular', icon: <Celebration /> },
        { text: 'Coming Soon', value: 'coming-soon', icon: <Timelapse /> },
    ];
};
