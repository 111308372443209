import React from 'react';
import { alpha, Box, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCustomTheme } from '../../../contexts/ThemeContext';

interface TopAppBarItemButtonLinkProps {
    icon: JSX.Element;
    text: string;
    link: string;
}

const TopAppBarItemButtonLink: React.FC<TopAppBarItemButtonLinkProps> = ({
    icon,
    text,
    link,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { theme } = useCustomTheme();

    return (
        <Button
            key={text}
            onClick={() => navigate(link)}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color:
                    location.pathname === link
                        ? theme.palette.text.primary
                        : theme.palette.text.disabled,
                bgcolor:
                    location.pathname === link
                        ? theme.palette.primary.main // Active button color
                        : 'inherit',
                '&:hover': {
                    bgcolor:
                        location.pathname === link
                            ? theme.palette.primary.main // Add slight transparency over active button
                            : alpha(theme.palette.primary.light, 0.15), // Semi-transparent yellow for non-active buttons
                },
                height: `64px`, // Match AppBar height
                width: `75px`, // Adjust width
                padding: 0, // Remove extra padding
                minWidth: 'auto', // Adjust button width to content
                borderRadius: 0, // Remove rounded borders
                textTransform: 'none', // Disable uppercase text
            }}
        >
            <Box
                sx={{
                    color: theme.palette.text.primary, // Icon color
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '1.5rem', // Increase icon size
                }}
            >
                {icon}
            </Box>
            {text}
        </Button>
    );
};

export default TopAppBarItemButtonLink;
