// SideBarMobile.tsx
import React from 'react';
import Box from '@mui/material/Box';
import { Drawer } from '@mui/material';
import SideBarItems from './SideBarItems';
import { discoveryToolBar } from '../../../helpers/Themes';

interface SideBarMobileProps {
    handleDrawerClose: () => void;
    drawerOpen: boolean;
    drawerWidth: number;
}

const SideBarMobile: React.FC<SideBarMobileProps> = ({
    drawerOpen,
    handleDrawerClose,
    drawerWidth,
}) => {
    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    overflowY: 'unset',
                    backgroundColor: discoveryToolBar, // Desired color
                    backgroundImage: 'none', // Ensure no background image
                },
            }}
            anchor={'left'}
            open={drawerOpen}
            onClose={handleDrawerClose}
        >
            <Box role="presentation">
                <SideBarItems
                    isMobile={true}
                    handleDrawerClose={handleDrawerClose}
                ></SideBarItems>
            </Box>
        </Drawer>
    );
};

export default SideBarMobile;
