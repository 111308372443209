// BottomProfileBar.tsx
import React from 'react';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import UserActions from '../../user/UserActions';
import { discoveryToolBar } from '../../../helpers/Themes';
import { User } from '../../../workers/ApiWorker';

interface BottomProfileBarProps {
    isMobile: boolean;
    user?: User;
}
const BottomProfileBar: React.FC<BottomProfileBarProps> = ({
    isMobile,
    user,
}) => {
    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 3,
            }}
            elevation={3}
        >
            <BottomNavigation sx={{ backgroundColor: discoveryToolBar }}>
                <UserActions isMobile={isMobile} user={user}></UserActions>
            </BottomNavigation>
        </Paper>
    );
};

export default BottomProfileBar;
