// src/components/Footer.js

import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
    return (
        <Box
            sx={{
                bgcolor: 'red',
                p: 2,
                textAlign: 'center',
                marginTop: 'auto',
                zIndex: 10, // Higher than the background but not too high
                position: 'relative', // Ensure it’s positioned above fixed background
            }}
        >
            <Typography variant="body2" color="text.secondary">
                &copy; {new Date().getFullYear()} Your Company. All rights
                reserved.
            </Typography>
        </Box>
    );
};

export default Footer;
