import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { isAsteriskPresent, mainLabel } from '../../../helpers/Labels';
import { useTheme } from '@mui/material/styles';
import CaptionInput from './CaptionInput';

interface TextInputProps {
    label: string;
    enableLabel?: boolean;
    isMobile?: boolean;
    caption?: string;
}

const LabelInput: React.FC<TextInputProps> = ({
    label,
    enableLabel,
    isMobile,
    caption,
}) => {
    const theme = useTheme(); // Using theme for default styling

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={isMobile ? 12 : 2.5}
            lg={enableLabel ? 12 : 2.2}
            xl={enableLabel ? 12 : 1.75}
        >
            {/* Label and Asterisk */}
            <Typography
                variant="h6"
                sx={{ display: 'flex', alignItems: 'center' }}
            >
                <Box
                    component="span"
                    sx={{ color: theme.palette.text.primary }}
                >
                    {mainLabel(label)}
                </Box>
                {isAsteriskPresent(label) && (
                    <Box
                        component="span"
                        sx={{
                            color: theme.palette.error.main,
                            marginLeft: '4px',
                        }}
                    >
                        *
                    </Box>
                )}
            </Typography>

            {/* Caption underneath the label */}
            {caption && <CaptionInput caption={caption} />}
        </Grid>
    );
};

export default LabelInput;
