// GradientBorder.tsx
import React from 'react';
import Box from '@mui/material/Box';
import { alpha } from '@mui/system';
import { useTheme } from '@mui/material/styles';

interface GradientBorderProps {}

const GradientBorder: React.FC<GradientBorderProps> = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: '1px',
                background: `linear-gradient(to right, transparent, ${alpha(
                    theme.palette.primary.light,
                    1
                )}, transparent)`,
            }}
        />
    );
};

export default GradientBorder;
