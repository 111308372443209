// HomePage.tsx
import React, { useEffect, useState } from 'react';
import { AvatarGroup, Fade, Typography } from '@mui/material';
import {
    UploadMember,
    UploadPublic,
    UploadTypeNames,
    User,
    UserAccountTypes,
    UserProfile,
} from '../workers/ApiWorker';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { useApi } from '../contexts/ApiContext';
import Box from '@mui/material/Box';
import Filters from '../components/filters/Filters';
import { searchFilters } from '../helpers/SearchFilters';
import Carousel from '../components/carousel/Carosel';
import Stack from '@mui/material/Stack';
import UserAvatar from '../components/user/UserAvatar';
import { fadeTimeout, getOverallPadding } from '../helpers/Themes';
import PageTopSection from '../components/universal/pageTopSection/PageTopSection';
import NoResultsFound from '../components/universal/loader/NoResultsFound';

interface HomePageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
}

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

const HomePage: React.FC<HomePageProps> = ({ isMobile, padding, user }) => {
    const { uploadTypes, token } = useApi();

    const defaultCount = 50;

    const apiWorker = useWorker(createApiWorker);

    const [selectedFilter, setSelectedFilter] = useState(
        searchFilters()[0].value
    );

    const userAvatarCount = 4;

    const [loadingCharacters, setLoadingCharacters] = useState<boolean>(true);
    const [loadingCharactersWomen, setLoadingCharactersWomen] =
        useState<boolean>(true);
    const [loadingArenas, setLoadingArenas] = useState<boolean>(true);
    const [loadingAuthors, setLoadingAuthors] = useState<boolean>(true);

    const [characterWomenUploads, setCharacterWomenUploads] = useState<
        UploadPublic[] | UploadMember[]
    >([]);

    const [characterUploads, setCharacterUploads] = useState<
        UploadPublic[] | UploadMember[]
    >([]);

    const [arenaUploads, setArenaUploads] = useState<
        UploadPublic[] | UploadMember[]
    >([]);

    const [authors, setAuthors] = useState<UserProfile[]>([]);
    const [selectedAuthors, setSelectedAuthors] = useState<UserProfile[]>([]);

    const handleSelectedFilter = (value: string) => {
        setSelectedFilter(value);
    };

    const handleGetUploads = async () => {
        setAuthors([]);
        setLoadingAuthors(true);
        setLoadingCharacters(true);
        setLoadingCharactersWomen(true);
        setLoadingArenas(true);
        if (uploadTypes) {
            // Get Characters
            const uploadTypeCharacterUuid = await apiWorker.getUploadTypeByName(
                uploadTypes,
                UploadTypeNames.CHARACTER
            );

            const uploadTypeArenaUuid = await apiWorker.getUploadTypeByName(
                uploadTypes,
                UploadTypeNames.ARENA
            );

            let getMyUploads = false;

            if (token && user) {
                if (
                    user.userProfile.accountType === UserAccountTypes.MEMBER &&
                    selectedFilter === 'coming-soon'
                ) {
                    getMyUploads = true;
                }
            }

            if (uploadTypeCharacterUuid) {
                const response = await apiWorker.getUploadsDirection(
                    getMyUploads,
                    token,
                    {
                        count: defaultCount,
                        type: uploadTypeCharacterUuid,
                        status: selectedFilter,
                    }
                );
                setCharacterUploads(response.data);

                const responseWomen = await apiWorker.getUploadsDirection(
                    getMyUploads,
                    token,
                    {
                        count: defaultCount,
                        type: uploadTypeCharacterUuid,
                        status: selectedFilter,
                        additionalTypeInformation: 'women',
                    }
                );
                setCharacterWomenUploads(responseWomen.data);
            } else {
                console.warn('Upload type not found - Character');
            }

            if (uploadTypeArenaUuid) {
                const responseArena = await apiWorker.getUploadsDirection(
                    getMyUploads,
                    token,
                    {
                        count: defaultCount,
                        type: uploadTypeArenaUuid,
                        status: selectedFilter,
                    }
                );
                setArenaUploads(responseArena.data);
            } else {
                console.warn('Upload type not found - Arena');
            }

            setLoadingCharacters(false);
            setLoadingCharactersWomen(false);
            setLoadingArenas(false);
        }
    };

    const getRandomAuthors = (authors: UserProfile[], count: number) => {
        // Shuffle array (Fisher-Yates Shuffle)
        const shuffled = [...authors].sort(() => 0.5 - Math.random());
        // Get up to the specified number of authors (or the length of authors array if smaller)
        return shuffled.slice(0, Math.min(count, authors.length));
    };

    useEffect(() => {}, []);

    useEffect(
        () => {
            handleGetUploads().catch(console.error);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedFilter, uploadTypes]
    );

    // Authors
    useEffect(() => {
        if (!loadingCharactersWomen && !loadingArenas && !loadingCharacters) {
            const updateAuthors = (
                uploads: (UploadPublic | UploadMember)[]
            ) => {
                const uniqueAuthors = new Map<string, UserProfile>();

                // Loop through each upload and add author to the Map (which keeps only unique values)
                uploads.forEach((upload) => {
                    const { uuid } = upload.author;
                    if (!uniqueAuthors.has(uuid)) {
                        uniqueAuthors.set(uuid, upload.author);
                    }
                });

                const allUniqueAuthors = Array.from(uniqueAuthors.values());

                // Convert map values to an array and update state
                setSelectedAuthors(
                    getRandomAuthors(allUniqueAuthors, userAvatarCount)
                );
                setAuthors(allUniqueAuthors);
            };

            // Combine all uploads into a single array
            const combinedUploads = [
                ...characterUploads,
                ...arenaUploads,
                ...characterWomenUploads,
            ];

            // If there are uploads, update authors
            if (combinedUploads.length > 0) {
                updateAuthors(combinedUploads);
            } else {
                setAuthors([]);
            }
            setLoadingAuthors(false);
        }
    }, [loadingCharacters, loadingCharactersWomen, loadingArenas]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                <PageTopSection
                    isMobile={isMobile}
                    padding={padding}
                    advancedContent={
                        <>
                            {/* Filters positioned at the bottom */}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: isMobile ? 20 : 0, // Align the filters to the bottom of the container
                                    left: 0,
                                    right: 0, // Ensures it stretches to both ends
                                }}
                            >
                                <Filters
                                    isMobile={isMobile}
                                    filters={searchFilters}
                                    selectedFilter={selectedFilter}
                                    handleSelectedFilter={handleSelectedFilter}
                                    padding={padding}
                                    user={user}
                                />
                            </Box>

                            {/* HELLO text on the right-hand side */}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: -30, // Vertically centers the text
                                    right: 20, // Adjust as needed to place it slightly from the edge
                                    transform: 'translateY(-50%)', // Ensures vertical centering
                                    paddingRight: padding,
                                }}
                            >
                                {!loadingAuthors &&
                                !isMobile &&
                                authors.length > 0 ? (
                                    <Fade in={true} timeout={fadeTimeout}>
                                        <div>
                                            <AvatarGroup>
                                                {authors.length > 0 &&
                                                selectedAuthors.length > 0
                                                    ? selectedAuthors.map(
                                                          (author, index) => (
                                                              <UserAvatar
                                                                  key={index}
                                                                  backup={
                                                                      author.username
                                                                  }
                                                                  dimensions={
                                                                      36
                                                                  }
                                                                  border={false}
                                                                  userProfile={
                                                                      author
                                                                  }
                                                              />
                                                          )
                                                      )
                                                    : null}

                                                {/* Show "+X" UserAvatar if there are more authors than the visible count */}
                                                {authors.length -
                                                    userAvatarCount >
                                                    0 && (
                                                    <UserAvatar
                                                        key={
                                                            userAvatarCount + 1
                                                        }
                                                        backup={`+${authors.length - userAvatarCount}`}
                                                        dimensions={36}
                                                        border={false}
                                                        total={true}
                                                    />
                                                )}
                                            </AvatarGroup>
                                        </div>
                                    </Fade>
                                ) : null}
                            </Box>
                        </>
                    }
                />
                <Box
                    sx={{
                        paddingLeft: getOverallPadding(isMobile, padding),
                        paddingRight: getOverallPadding(isMobile, padding),
                        marginBottom: isMobile ? 10 : undefined,
                    }}
                >
                    <Stack
                        sx={{
                            textAlign: 'left',
                        }}
                    >
                        {!loadingCharacters ? (
                            <Fade in={true} timeout={fadeTimeout}>
                                {characterUploads.length > 0 ? (
                                    <div>
                                        <Carousel
                                            slidesPerView={4}
                                            data={characterUploads}
                                            type={UploadTypeNames.CHARACTER}
                                            index={1}
                                            isMobile={isMobile}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <NoResultsFound />
                                    </div>
                                )}
                            </Fade>
                        ) : null}
                        {!loadingArenas ? (
                            <Fade in={true} timeout={fadeTimeout}>
                                <div>
                                    <Typography
                                        variant={'h6'}
                                        sx={{
                                            marginBottom: 2,
                                        }}
                                    >
                                        ARENA
                                    </Typography>
                                    {arenaUploads.length > 0 ? (
                                        <div>
                                            <Carousel
                                                slidesPerView={2}
                                                data={arenaUploads}
                                                type={UploadTypeNames.ARENA}
                                                index={2}
                                                isMobile={isMobile}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <NoResultsFound />
                                        </div>
                                    )}
                                </div>
                            </Fade>
                        ) : null}
                        {!loadingCharactersWomen ? (
                            <Fade in={true} timeout={fadeTimeout}>
                                <div>
                                    <Typography
                                        variant={'h6'}
                                        sx={{
                                            marginBottom: 2,
                                        }}
                                    >
                                        WOMEN CHARACTERS
                                    </Typography>
                                    {characterWomenUploads.length > 0 ? (
                                        <div>
                                            <Carousel
                                                slidesPerView={4}
                                                data={characterWomenUploads}
                                                type={UploadTypeNames.CHARACTER}
                                                index={3}
                                                isMobile={isMobile}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <NoResultsFound />
                                        </div>
                                    )}
                                </div>
                            </Fade>
                        ) : null}
                    </Stack>
                </Box>
            </Box>
        </Fade>
    );
};

export default HomePage;
