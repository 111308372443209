// AppRoutes.tsx
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { links } from '../helpers/Links';
import { User } from '../workers/ApiWorker';

interface AppRoutesProps {
    isMobile: boolean;
    padding: number;
    user?: User;
}

const AppRoutes: React.FC<AppRoutesProps> = ({ isMobile, padding, user }) => {
    const [pages, setPages] = React.useState(links(isMobile, padding, user));

    useEffect(() => {
        setPages(links(isMobile, padding, user));
    }, [isMobile, padding, user]);

    return (
        <Routes>
            {pages.map((item, index) => {
                return (
                    <Route
                        key={item.name}
                        path={item.path}
                        element={item.page}
                    />
                );
            })}
        </Routes>
    );
};

export default AppRoutes;
